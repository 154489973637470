<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page_header_new";

import axios from "axios";

import {mapState} from "vuex";
import {loggedInfoObject} from "@/state/helpers";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import {Korean} from "flatpickr/dist/l10n/ko.js"

import {AgGridVue} from 'ag-grid-vue'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import {ImageEditor} from '@toast-ui/vue-image-editor'
import {blackTheme} from "@/views/pages/maclTalk/imageEditorTheme";
import {msgConfirmOptCenter, msgModalOptCenter} from "@/components/globalFunctions";

import ResizeModal from "@/views/pages/maclTalk/Modules/resizeModal";

export default {
  page: {
    title: "마클톡",
    meta: [
      {
        name: "마클톡",
        /*content: appConfig.description,*/
      },
    ],
  },
  data() {
    return {
      title: "마클톡",
      items: "마클톡 서비스를 이용하실 수 있습니다.",
      talkList: [],
      sendSelect: 'now',
      sendNow: new Date(),
      resvTime: '13:00',
      resvDate: new Date(),
      /*new Date().toJSON().slice(0, 10)*/
      timeOptions: {
        enableTime: true,
        noCalendar: true,
        time_24hr: true,
        dateFormat: "H:i"
      },
      config: {
        enableTime: false,
        minDate: 'today',
        dateFormat: "Y-m-d",
        locale: Korean,
      },
      gridApi: null,
      columnApi: null,
      columnDefs: [
        {
          headerName: '#',
          field: 'mk_custom_srl',
          checkboxSelection: true,
          headerCheckboxSelection: true,
        },
        {headerName: '고객명', field: 'name'},
        {headerName: '연락처', field: 'phone'}
      ],
      messageType: 'text',
      textLength: '0',
      textTypeContent: '',
      srchProductKeyword: '',
      srchProductList: [],
      singleProduct: {
        name: '상품명',
        img: 'https://cdn1.critalk.com/static/images/no_image.png',
        saleprice: '0000',
        price: '0000',
        percent: '00'
      },
      listProducts: [
        {
          name: '상품명',
          img: 'https://cdn1.critalk.com/static/images/no_image.png',
          saleprice: '0000',
          price: '0000',
          percent: '00'
        },
        {
          name: '상품명',
          img: 'https://cdn1.critalk.com/static/images/no_image.png',
          saleprice: '0000',
          price: '0000',
          percent: '00'
        },
        {
          name: '상품명',
          img: 'https://cdn1.critalk.com/static/images/no_image.png',
          saleprice: '0000',
          price: '0000',
          percent: '00'
        }
      ],
      options: {
        includeUI: {
          loadImage: {
            path: this.selectImageUrl,
            name: 'img',
          },
          theme: blackTheme,
          menu: [
            'resize',
            //'crop',
            'flip',
            //'rotate',
            'draw',
            'shape',
            'icon',
            'text',
            'mask',
            'filter'
          ],
          initMenu: 'text',
          menuBarPosition: 'right',
          uiSize: {
            width: '100%',
            height: '100%'
          },
        },
        cssMaxWidth: 400,
        cssMaxHeight: 400,
      },
      defaultImagesUrl: [],
      backgroundImagesUrl: [],
      selectImageUrl: 'https://cdn1.critalk.com/static/images/no_image.png',
      testUrl: '',
      sendMemberList: [],
      customName: '',
      customPrice: '',
      groupList: '',
      groupValue: 'all',
      groupName: "전체",
      tDate: this.toStringByFormatting('link', new Date()),
    };
  },
  components: {
    Layout,
    //simplebar,
    AgGridVue,
    PageHeader,
    flatPickr,
    "tui-image-editor": ImageEditor,
    ResizeModal,
  },
  computed: {
    ...mapState(loggedInfoObject),
    $textarea() {
      return document.getElementById("talk_preview_content")
    }
  },
  mounted() {
    this.callTalkMember()
    this.getGroupList()

    this.madeDefautImageUrl()
    this.madeBackgroundImageUrl()

    // console.log('-----------------------------', typeof this.logged_info.mart_srl)
  },
  filters: {
    comma(val) {
      return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  },
  methods: {
    //수신자 리스트 호출
    callTalkMember() {
      /*let url = "/api/kakao/custom/306"*/

      let url = `/api/kakao/custom/${this.logged_info.mart_srl}`
      axios.get(url).then((response) => {
        //console.log(response.data[0])

        this.talkList = response.data
      })
    },

    //이미지 base64로 가져오는 함수
    getImage(bvModalEvent) {
      //console.log('checkTEST REsult', this.options.includeUI.loadImage)
      //this.selectSendTime()
      //console.log('!!!!!!!!!!!!!!!!', this.selectImageUrl)

      //this.options.includeUI.loadImage.path =

      let img = this.$refs.imageEditor.invoke('toDataURL', {
        format: 'jpeg',
      })

      var head = 'data:image/png;base64,';
      // let img = this.$refs.imageEditor.invoke('toDataURL')
      let fileSize = Math.round((((img.length - head.length) * 3 / 4) / 1000 / 1000) * 10) / 10

      if (fileSize > 2) {
        console.log('이야 너무 크다')
        this.$bvModal.show('resizeGuide')
        bvModalEvent.preventDefault();

        return
      }

      //console.log('@@@@@@@@@@@@@@', img, '\n', fileSize)

      this.selectImageUrl = img
    },

    //Ag-Grid 관련 Methods
    onFirstDataRendered(event) {
      event.api.sizeColumnsToFit()
    },
    onFilterTextBoxChanged() {
      this.gridApi.setQuickFilter(this.$refs.default_input.value)
    },
    onGridReady(params) {
      this.gridApi = params.api
    },
    getSelectedRowData() {
      let selectNodes = this.gridApi.getSelectedNodes()
      let selectData = selectNodes.map(node => node.data)
      /*console.log(selectData)
      console.log(JSON.stringify(selectData[0].mk_custom_srl))*/
      var list = []
      selectData.forEach(function (item) {
        list.push(item.mk_custom_srl)
      })
      this.sendMemberList = list
      //console.log(this.sendMemberList.toString())
    },

    //마클톡 레이아웃 선택 초기화
    changeTalkLayout(val) {
      this.messageType = val
      //console.log(this.messageType)
    },

    //텍스트 타입 글자수 확인
    checkTextcnt(e) {
      let val = e.target.value

      this.textLength = val.length
      this.resize()
    },

    //톡 보낼 상품 검색하기
    srchProduct() {
      let url = `/api/openmarket/search/market/product/0000/${this.logged_info.biznum}/${this.srchProductKeyword}`
      let res = encodeURI(url);

      axios.get(res).then((response) => {
        //console.log(response.data)
        for (let idx = 0; idx < response.data.length; idx++) {
          if (response.data[idx].img == '') {
            response.data[idx].img = 'https://cdn1.critalk.com/static/images/no_image.png'
          }
        }
        this.srchProductList = response.data
      }).catch((error) => {
        console.log(error)
      })
    },

    //톡 보낼 상품 선택하기
    selectProduct(product, type, ordering) {
      //console.log(product, type)

      if (type == 'single') {
        this.$bvModal.msgBoxConfirm(`[${product.name.replaceAll('_', ' ')}] 제품을 선택하시겠습니까?`, msgConfirmOptCenter)
            .then((sel) => {
              if (sel) {
                this.singleProduct = product

                this.$bvModal.hide('modal-center')

                this.srchProductKeyword = ''
                this.srchProductList = []
              }
            })
      } else if (type == 'list') {
        this.$bvModal.msgBoxConfirm(`[${product.name.replaceAll('_', ' ')}] 제품을 선택하시겠습니까?`, msgConfirmOptCenter)
            .then((sel) => {
              if (sel) {
                switch (ordering) {
                  case 0:
                    this.listProducts[0] = product
                    break;
                  case 1:
                    this.listProducts[1] = product
                    break;
                  case 2:
                    this.listProducts[2] = product
                    break;
                }
                this.$bvModal.hide(`modal-center-list-${ordering}`)

                this.srchProductKeyword = ''
                this.srchProductList = []
              }
            })
      }


    },

    //전송 시간 선택하기
    selectSendTime() {
      if (this.sendSelect == 'now') {
        //console.log(this.toStringByFormatting(this.sendNow, new Date()))
        this.tDate = this.toStringByFormatting('link', new Date())
        return this.toStringByFormatting(this.sendNow, new Date())
      } else {
        //console.log(this.toStringByFormatting('dateOnly', this.resvDate) + ' ' + this.resvTime)
        //console.log(this.resvDate)

        try {
          this.tDate = this.toStringByFormatting('link', new Date(this.resvDate))
          console.log('---------------------------------', this.tDate)
          return this.toStringByFormatting('dateOnly', new Date(this.resvDate)) + " " + this.resvTime + ":00"
        } catch {
          return this.resvDate + ' ' + this.resvTime + ':00'
        }
      }
    },

    //날짜 formating
    leftPad(value) {
      if (value >= 10) {
        return value;
      }
      return `0${value}`
    },

    //날짜 formating
    toStringByFormatting(select, source) {
      //console.log(source)
      //console.log(new Date().toJSON())
      const year = source.getFullYear();
      const month = this.leftPad(source.getMonth() + 1);
      const day = this.leftPad(source.getDate());

      let hour = source.getHours()
      hour = hour >= 10 ? hour : '0' + hour
      let min = source.getMinutes()
      min = min >= 10 ? min : '0' + min
      let sec = source.getSeconds()
      sec = sec >= 10 ? sec : '0' + sec

      let time = ''

      if (select == 'dateOnly') {
        time = `${year}-${month}-${day}`
      } else if (select == 'link') {
        time = `${year}${month}${day}`
      } else {
        time = `${year}-${month}-${day} ${hour}:${min}:${sec}`
      }

      return time
    },

    //imageEditor에 들어갈 기본 이미지
    madeDefautImageUrl() {
      /* let fileName = [
         "57.jpg",
         "coupon1.png",
         "coupon2.png",
         "coupon3.png",
         "event_1.png",
         "event_10.png",
         "event_11.png",
         "event_12.png",
         "event_13.png",
         "event_14.png",
         "event_15.png",
         "event_16.png",
         "event_17.png",
         "event_18.png",
         "event_19.png",
         "event_2.png",
         "event_20.png",
         "event_21.png",
         "event_22.png",
         "event_23.png",
         "event_24.png",
         "event_25.png",
         "event_26.png",
         "event_27.png",
         "event_28.png",
         "event_29.png",
         "event_30.png",
         "event_31.png",
         "event_32.png",
         "event_33.png",
         "event_34.png",
         "event_35.png",
         "event_36.png",
         "event_37.png",
         "event_38.png",
         "event_39.jpg",
         "event_4.png",
         "event_40.jpg",
         "event_41.jpg",
         "event_42.jpg",
         "event_43.jpg",
         "event_44.jpg",
         "event_45.jpg",
         "event_46.jpg",
         "event_47.jpg",
         "event_48.jpg",
         "event_49.jpg",
         "event_5.png",
         "event_50.jpg",
         "event_51.jpg",
         "event_52.jpg",
         "event_53.jpg",
         "event_54.jpg",
         "event_55.jpg",
         "event_56.jpg",
         "event_6.png",
         "event_7.png",
         "event_8.png",
         "event_9.png",
         "event_64.jpg",
         "event_65.jpg",
         "event_66.jpg",
         "event_67.jpg",
         "event_68.jpg",
         "event_69.jpg",
         "event_70.jpg",
         "event_71.jpg",
         "event_72.jpg",
         "event_73.jpg",
         "event_74.jpg",
         "event_75.jpg",
         "event_76.jpg",
         "event_77.jpg",
         "event_78.jpg",
         "event_79.jpg",
         "event_80.jpg",
         "event_81.jpg",
         "event_82.jpg",
         "event_83.jpg",
         "event_84.jpg",
         "event_85.jpg",
         "event_86.jpg",
         "event_87.jpg",
         "event_88.jpg",
         "event_89.jpg",
         "event_90.jpg",
         "event_91.jpg",
         "event_92.jpg",
         "event_93.jpg",
         "event_94.jpg",
         "event_95.jpg",
         "event_96.jpg",
         "event_97.jpg",
         "event_98.jpg",
         "event_99.jpg",
         "event_100.jpg",
         "event_101.jpg",
         "event_102.jpg",
         "event_103.jpg",
         "event_104.jpg",
         "event_105.jpg",
         "event_106.jpg",
         "event_107.jpg",
         "event_108.jpg",
         "event_109.jpg",
         "event_110.jpg",
         "event_111.jpg",
         "event_112.jpg",
         "event_113.jpg",
         "event_114.jpg",
         "event_115.jpg",
         "event_116.jpg",
         "event_117.jpg",
         "event_118.jpg",
         "event_119.jpg",
         "event_120.jpg",
         "event_121.jpg",
         "event_122.jpg",
         "event_123.jpg",
         "event_124.jpg",
         "event_125.jpg",
         "event_126.jpg",
         "event_127.jpg",
         "event_128.jpg",
         "event_129.jpg",
         "event_130.jpg",
         "event_131.jpg",
         "event_132.jpg",
         "event_133.jpg",
         "event_134.jpg",
         "event_135.jpg",
         "event_136.jpg",
         "event_137.jpg",
         "event_138.jpg",
         "event_139.jpg",
         "event_140.jpg",
         "event_141.jpg",
         "event_142.jpg",
         "event_143.jpg",
         "event_144.jpg",
         "event_145.jpg",
         "event_146.jpg",
         "event_147.jpg",
         "event_148.jpg",
         "event_149.jpg",
         "event_150.jpg",
         "event_151.jpg",
         "event_152.jpg",
         "event_153.jpg",
         "event_154.jpg",
         "event_155.jpg",
         "event_156.jpg",
         "event_157.jpg",
         "event_158.jpg",
         "event_159.jpg",
         "event_160.jpg",
         "event_161.jpg",
         "41.jpg",
         "42.jpg",
         "43.jpg",
         "44.jpg",
         "45.jpg",
         "46.jpg",
         "47.jpg",
         "48.jpg",
         "49.jpg",
         "50.jpg",
         "51.jpg",
         "52.jpg",
         "53.jpg",
         "54.jpg",
         "55.jpg",
       ].reverse()
       let tmpUrls = []


       fileName.forEach(function (row) {
         let url = `https://adm.critalk.com/uploads/kakao_link/events/${row}`
         let tmp = {'img_path': url}
         tmpUrls.push(tmp)
       })

       this.defaultImagesUrl = tmpUrls
       console.log(this.defaultImagesUrl)*/

      // tmpUrls.push({'img':'https://cdn1.critalk.com/imgs/macltalk/bg/57.jpg'})

      let url = '/api/maclbg/list'

      axios.get(url)
          .then((res) => {
            // console.log(res.data)

            this.defaultImagesUrl = res.data
          })
          .catch(err => console.log('err', err))
    },

    //imageEditor에 들어갈 기본 배경 이미지
    madeBackgroundImageUrl() {
      let files = ["bg1.png", "bg10.png", "bg11.png", "bg12.png", "bg13.png", "bg14.png", "bg15.png", "bg16.png", "bg17.png", "bg18.png", "bg19.png", "bg2.png", "bg20.png", "bg21.png", "bg22.png", "bg23.png", "bg24.png", "bg25.png", "bg26.png", "bg27.png", "bg28.png", "bg29.png", "bg3.png", "bg4.png", "bg5.png", "bg6.png", "bg7.png", "bg8.png", "bg9.png", "bg_color_1.png", "bg_color_10.png", "bg_color_11.png", "bg_color_12.png", "bg_color_13.png", "bg_color_14.png", "bg_color_15.png", "bg_color_16.png", "bg_color_2.png", "bg_color_3.png", "bg_color_4.png", "bg_color_5.png", "bg_color_6.png", "bg_color_7.png", "bg_color_8.png", "bg_color_9.png"].reverse()
      let tmpUrls = []

      files.forEach(function (row) {
        let url = `https://cdn1.critalk.com/uploads/kakao_link/backgrounds/${row}`
        let tmp = {'img_path': url}
        tmpUrls.push(tmp)
      })

      this.backgroundImagesUrl = tmpUrls
    },

    //기본제공 이미지 선택 시
    /*    selectDefaultImage(val) {
          console.log(this.logged_info)
          if ("선택하신 이미지로 선택하시겠습니까?")) {
            this.selectImageUrl = val
            this.$bvModal.hide('image-edit')
          }
        },*/

    //background image 선택 시
    selectBackgroundImage(val) {
      //this.$refs.imageEditor.invoke('toDataURL')
      if (val.includes('cdn1')) {
        val = val.replace('cdn1', 'adm')
        console.warn(val)
      }
      console.log(val)

      let img = this.$refs.imageEditor.invoke('loadImageFromURL', val, 'backgroundImage')
      this.selectImageUrl = img

      this.$refs.imageEditor.invoke('ui.activeMenuEvent')
    },

    //마클톡 보내기
    sendMaclTalk() {
      let source = new Date()
      const year = source.getFullYear()
      const month = this.leftPad(source.getMonth() + 1)
      const day = this.leftPad(source.getDate())

      let hour = source.getHours()
      hour = hour >= 10 ? hour : '0' + hour
      let min = source.getMinutes()
      min = min >= 10 ? min : '0' + min
      let sec = source.getSeconds()
      sec = sec >= 10 ? sec : '0' + sec

      let curTime = ''
      curTime = `${year}` + `${month}` + `${day}` + hour + min + sec
      // console.log(curTime)

      let rTime = ''
      if (this.sendSelect === 'res') {
        let sources = new Date(this.resvDate)
        const years = sources.getFullYear()
        const months = this.leftPad(sources.getMonth() + 1)
        const days = this.leftPad(sources.getDate())

        rTime = `${years}` + `${months}` + `${days}` + this.resvTime.replaceAll(':', '') + "00"
        console.log(rTime)
      }

      if (this.sendSelect === 'res' && curTime >= rTime) {
        console.log('test', curTime, rTime)
        this.$bvModal.msgBoxOk('예약시간을 확인해주세요', msgModalOptCenter)
        return
      }

      if (this.logged_info.business_type !== 'clan') {
        if (this.logged_info.macltalk_oversend === 'Y') {
          if (this.sendSelect === 'res' && rTime.slice(8, 14) > '200000') {
            this.$bvModal.msgBoxOk('마지막 발송시간은 20:00입니다. 예약시간을 확인해주세요.', msgModalOptCenter)
            console.log(rTime.slice(8, 14))
            return;
          } else if (this.sendSelect === 'res' && rTime.slice(8, 14) < '080000') {
            console.log('up')
            this.$bvModal.msgBoxOk('첫 발송시간은 08:00입니다. 예약시간을 확인해주세요.', msgModalOptCenter)
            return
          }

          if (this.sendSelect === 'now' && curTime.slice(8, 14) > '200000') {
            this.$bvModal.msgBoxOk('마지막 발송시간은 20:00입니다. 현재시간을 확인해주세요.', msgModalOptCenter)
            console.log(curTime.slice(8, 14))
            return;
          } else if (this.sendSelect === 'now' && curTime.slice(8, 14) < '080000') {
            this.$bvModal.msgBoxOk('첫 발송시간은 08:00입니다. 현재시간을 확인해주세요.', msgModalOptCenter)
            console.log(curTime.slice(8, 14))
            return
          }
        } else {
          //console.log('rtime.slice', rTime.slice(8,14), rTime)

          if (this.sendSelect === 'res' && rTime.slice(8, 14) > '170000') {
            this.$bvModal.msgBoxOk('마지막 발송시간은 17:00입니다. 예약시간을 확인해주세요.', msgModalOptCenter)
            console.log(rTime.slice(8, 14))
            return;
          } else if (this.sendSelect === 'res' && rTime.slice(8, 14) < '080000') {
            console.log('up')
            this.$bvModal.msgBoxOk('첫 발송시간은 08:00입니다. 예약시간을 확인해주세요.', msgModalOptCenter)
            return
          }

          if (this.sendSelect === 'now' && curTime.slice(8, 14) > '170000') {
            this.$bvModal.msgBoxOk('마지막 발송시간은 17:00입니다. 현재시간을 확인해주세요.', msgModalOptCenter)
            console.log(curTime.slice(8, 14))
            return;
          } else if (this.sendSelect === 'now' && curTime.slice(8, 14) < '080000') {
            this.$bvModal.msgBoxOk('첫 발송시간은 08:00입니다. 현재시간을 확인해주세요.', msgModalOptCenter)
            console.log(curTime.slice(8, 14))
            return
          }
        }
      }

      let fd = new FormData();
      let productObject = {};

      this.getSelectedRowData()
      let allOrNot = 'not'
      if (this.sendMemberList.length != 0) {
        this.$bvModal.msgBoxConfirm(`총 ${this.sendMemberList.length}명의 수신자에게 마클톡을 전송하시겠습니까?`, msgConfirmOptCenter)
            .then((sel) => {
              if (sel) {
                switch (this.messageType) {

                  case 'text': {
                    if (this.chkImoji(this.textTypeContent)) {
                      if (this.sendMemberList.length == this.talkList.length) {
                        if (this.groupName === "전체") {
                          //console.log("allllllllllllll")
                          allOrNot = 'all'
                          if (this.logged_info.business_type == 'clan') {
                            productObject = {
                              'groupnum': '0',
                              'rsrv_time': this.selectSendTime(),
                              'msg': `${this.textTypeContent}` +
                                  `\n\n★링크★\n${this.logged_info.macltalk_link}`
                            }
                          } else {
                            productObject = {
                              'groupnum': '0',
                              'rsrv_time': this.selectSendTime(),
                              'msg': `(광고)/${this.logged_info.mart_name}` +
                                  `\n\n${this.textTypeContent}` +
                                  `\n\n★링크★\nhttps://market.critalk.com/product?market_srl=${this.logged_info.mart_srl}&tdate=${this.tDate}` +
                                  `\n\n${this.logged_info.tel}` +
                                  `\n\n수신을 원치 않으시면 카카오톡 "친구차단" 기능을 이용해주세요.`,
                            }
                          }
                        } else {
                          //console.log("allllllllllllll + alllllllll   " + this.groupValue)
                          allOrNot = 'all'
                          if (this.logged_info.business_type == 'clan') {
                            productObject = {
                              'groupnum': this.groupValue,
                              'rsrv_time': this.selectSendTime(),
                              'msg': `(광고)/${this.logged_info.mart_name}` +
                                  `\n\n${this.textTypeContent}` +
                                  `\n\n★링크★\n${this.logged_info.macltalk_link}`
                            }
                          } else {
                            productObject = {
                              'groupnum': this.groupValue,
                              'rsrv_time': this.selectSendTime(),
                              'msg': `(광고)/${this.logged_info.mart_name}` +
                                  `\n\n${this.textTypeContent}` +
                                  `\n\n★링크★\nhttps://market.critalk.com/product?market_srl=${this.logged_info.mart_srl}&tdate=${this.tDate}` +
                                  `\n\n${this.logged_info.tel}` +
                                  `\n\n수신을 원치 않으시면 카카오톡 "친구차단" 기능을 이용해주세요.`,
                            }
                          }
                        }
                        //break
                      } else {
                        //console.log("nottttttttttttt")
                        allOrNot = 'not'

                        if (this.logged_info.business_type == 'clan') {
                          productObject = {
                            'customlist': this.sendMemberList.toString(),
                            'rsrv_time': this.selectSendTime(),
                            'msg': `(광고)/${this.logged_info.mart_name}` +
                                `\n\n${this.textTypeContent}` +
                                `\n\n★링크★\n${this.logged_info.macltalk_link}`
                          }
                        } else {
                          productObject = {
                            'customlist': this.sendMemberList.toString(),
                            'rsrv_time': this.selectSendTime(),
                            'msg': `(광고)/${this.logged_info.mart_name}` +
                                `\n\n${this.textTypeContent}` +
                                `\n\n★링크★\nhttps://market.critalk.com/product?market_srl=${this.logged_info.mart_srl}&tdate=${this.tDate}` +
                                `\n\n${this.logged_info.tel}` +
                                `\n\n수신을 원치 않으시면 카카오톡 "친구차단" 기능을 이용해주세요.`,
                          }
                        }
                      }

                      fd = productObject

                      if (this.textLength > 0 && this.textLength <= 360) {
                        this.uploadImage(fd, allOrNot)
                      } else {
                        if (this.logged_info.macltalk_text_length !== '360') {
                          this.uploadImage(fd, allOrNot)
                        } else {
                          this.$bvModal.msgBoxOk('글자수를 확인해주세요.', msgModalOptCenter)
                        }
                      }
                    } else {
                      return
                    }
                    console.log(this.textTypeContent)
                    break
                  }

                  case 'single':
                    //console.log(this.messageType)
                    if (this.sendMemberList.length == this.talkList.length) {
                      allOrNot = 'all'
                      if (this.groupName === "전체") {
                        productObject = {
                          "rsrv_time": this.selectSendTime(),
                          "groupnum": '0',
                          "msg": {
                            "sending_msg_type": 'single',
                            "target_msg": {
                              "img": `${this.singleProduct.img}`,
                              "name": `${this.singleProduct.name}`,
                              "price": `${this.singleProduct.saleprice == "" ? this.singleProduct.price : this.singleProduct.saleprice}`,
                              "pre_price": `${this.singleProduct.price == '' ? this.singleProduct.saleprice : this.singleProduct.price}`,
                              "percent": `${this.singleProduct.percent}`,
                              "link_url": `/detail?market_srl=${this.logged_info.mart_srl}&psrl=${this.singleProduct.product_srl}&apitype=v2&tdate=${this.tDate}`
                            }
                          },
                        }
                      } else {
                        productObject = {
                          "rsrv_time": this.selectSendTime(),
                          "groupnum": this.groupValue,
                          "msg": {
                            "sending_msg_type": 'single',
                            "target_msg": {
                              "img": `${this.singleProduct.img}`,
                              "name": `${this.singleProduct.name}`,
                              "price": `${this.singleProduct.saleprice == "" ? this.singleProduct.price : this.singleProduct.saleprice}`,
                              "pre_price": `${this.singleProduct.price == '' ? this.singleProduct.saleprice : this.singleProduct.price}`,
                              "percent": `${this.singleProduct.percent}`,
                              "link_url": `/detail?market_srl=${this.logged_info.mart_srl}&psrl=${this.singleProduct.product_srl}&apitype=v2&tdate=${this.tDate}`
                            }
                          },
                        }
                      }
                    } else {
                      allOrNot = 'not'
                      productObject = {
                        "rsrv_time": this.selectSendTime(),
                        "customlist": this.sendMemberList.toString(),
                        "msg": {
                          "sending_msg_type": 'single',
                          "target_msg": {
                            "img": `${this.singleProduct.img}`,
                            "name": `${this.singleProduct.name}`,
                            "price": `${this.singleProduct.saleprice == "" ? this.singleProduct.price : this.singleProduct.saleprice}`,
                            "pre_price": `${this.singleProduct.price == '' ? this.singleProduct.saleprice : this.singleProduct.price}`,
                            "percent": `${this.singleProduct.percent}`,
                            "link_url": `/detail?market_srl=${this.logged_info.mart_srl}&psrl=${this.singleProduct.product_srl}&apitype=v2&tdate=${this.tDate}`
                          }
                        },
                      }
                    }

                    fd = productObject

                    if (this.singleProduct.name == '상품명' && this.singleProduct.img == 'https://cdn1.critalk.com/static/images/no_image.png') {
                      this.$bvModal.msgBoxOk('단일상품 타입 메시지에 선택된 상품이 없습니다.', msgModalOptCenter)
                    } else {
                      this.uploadImage(fd, allOrNot)
                    }

                    break

                  case'list':
                    //console.log(this.messageType)
                    if (this.sendMemberList.length == this.talkList.length) {
                      allOrNot = 'all'
                      if (this.groupName === "전체") {
                        productObject = {
                          "groupnum": '0',
                          "rsrv_time": this.selectSendTime(),
                          "msg": {
                            "sending_msg_type": 'list',
                            "target_msg": [
                              {
                                "img": `${this.listProducts[0].img}`,
                                "name": `${this.listProducts[0].name}`,
                                "price": `${this.listProducts[0].saleprice == "" ? this.listProducts[0].price : this.listProducts[0].saleprice}`,
                                "pre_price": `${this.listProducts[0].price == "" ? this.listProducts[0].saleprice : this.listProducts[0].price}`,
                                "percent": `${this.listProducts[0].percent}`,
                                "link_url": `/detail?market_srl=${this.logged_info.mart_srl}&psrl=${this.listProducts[0].product_srl}&apitype=v2&tdate=${this.tDate}`
                              },
                              {
                                "img": `${this.listProducts[1].img}`,
                                "name": `${this.listProducts[1].name}`,
                                "price": `${this.listProducts[1].saleprice == "" ? this.listProducts[1].price : this.listProducts[1].saleprice}`,
                                "pre_price": `${this.listProducts[1].price == "" ? this.listProducts[1].saleprice : this.listProducts[1].price}`,
                                "percent": `${this.listProducts[1].percent}`,
                                "link_url": `/detail?market_srl=${this.logged_info.mart_srl}&psrl=${this.listProducts[1].product_srl}&apitype=v2&tdate=${this.tDate}`
                              },
                              {
                                "img": `${this.listProducts[2].img}`,
                                "name": `${this.listProducts[2].name}`,
                                "price": `${this.listProducts[2].saleprice == "" ? this.listProducts[2].price : this.listProducts[2].saleprice}`,
                                "pre_price": `${this.listProducts[2].price == "" ? this.listProducts[2].saleprice : this.listProducts[2].price}`,
                                "percent": `${this.listProducts[2].percent}`,
                                "link_url": `/detail?market_srl=${this.logged_info.mart_srl}&psrl=${this.listProducts[2].product_srl}&apitype=v2&tdate=${this.tDate}`
                              }
                            ]
                          },
                        }
                      } else {
                        productObject = {
                          "groupnum": this.groupValue,
                          "rsrv_time": this.selectSendTime(),
                          "msg": {
                            "sending_msg_type": 'list',
                            "target_msg": [
                              {
                                "img": `${this.listProducts[0].img}`,
                                "name": `${this.listProducts[0].name}`,
                                "price": `${this.listProducts[0].saleprice == "" ? this.listProducts[0].price : this.listProducts[0].saleprice}`,
                                "pre_price": `${this.listProducts[0].price == "" ? this.listProducts[0].saleprice : this.listProducts[0].price}`,
                                "percent": `${this.listProducts[0].percent}`,
                                "link_url": `/detail?market_srl=${this.logged_info.mart_srl}&psrl=${this.listProducts[0].product_srl}&apitype=v2&tdate=${this.tDate}`
                              },
                              {
                                "img": `${this.listProducts[1].img}`,
                                "name": `${this.listProducts[1].name}`,
                                "price": `${this.listProducts[1].saleprice == "" ? this.listProducts[1].price : this.listProducts[1].saleprice}`,
                                "pre_price": `${this.listProducts[1].price == "" ? this.listProducts[1].saleprice : this.listProducts[1].price}`,
                                "percent": `${this.listProducts[1].percent}`,
                                "link_url": `/detail?market_srl=${this.logged_info.mart_srl}&psrl=${this.listProducts[1].product_srl}&apitype=v2&tdate=${this.tDate}`
                              },
                              {
                                "img": `${this.listProducts[2].img}`,
                                "name": `${this.listProducts[2].name}`,
                                "price": `${this.listProducts[2].saleprice == "" ? this.listProducts[2].price : this.listProducts[2].saleprice}`,
                                "pre_price": `${this.listProducts[2].price == "" ? this.listProducts[2].saleprice : this.listProducts[2].price}`,
                                "percent": `${this.listProducts[2].percent}`,
                                "link_url": `/detail?market_srl=${this.logged_info.mart_srl}&psrl=${this.listProducts[2].product_srl}&apitype=v2&tdate=${this.tDate}`
                              }
                            ]
                          },
                        }
                      }
                    } else {
                      allOrNot = 'not'
                      productObject = {
                        "customlist": this.sendMemberList.toString(),
                        "rsrv_time": this.selectSendTime(),
                        "msg": {
                          "sending_msg_type": 'list',
                          "target_msg": [
                            {
                              "img": `${this.listProducts[0].img}`,
                              "name": `${this.listProducts[0].name}`,
                              "price": `${this.listProducts[0].saleprice == "" ? this.listProducts[0].price : this.listProducts[0].saleprice}`,
                              "pre_price": `${this.listProducts[0].price == "" ? this.listProducts[0].saleprice : this.listProducts[0].price}`,
                              "percent": `${this.listProducts[0].percent}`,
                              "link_url": `/detail?market_srl=${this.logged_info.mart_srl}&psrl=${this.listProducts[0].product_srl}&apitype=v2&tdate=${this.tDate}`
                            },
                            {
                              "img": `${this.listProducts[1].img}`,
                              "name": `${this.listProducts[1].name}`,
                              "price": `${this.listProducts[1].saleprice == "" ? this.listProducts[1].price : this.listProducts[1].saleprice}`,
                              "pre_price": `${this.listProducts[1].price == "" ? this.listProducts[1].saleprice : this.listProducts[1].price}`,
                              "percent": `${this.listProducts[1].percent}`,
                              "link_url": `/detail?market_srl=${this.logged_info.mart_srl}&psrl=${this.listProducts[1].product_srl}&apitype=v2&tdate=${this.tDate}`
                            },
                            {
                              "img": `${this.listProducts[2].img}`,
                              "name": `${this.listProducts[2].name}`,
                              "price": `${this.listProducts[2].saleprice == "" ? this.listProducts[2].price : this.listProducts[2].saleprice}`,
                              "pre_price": `${this.listProducts[2].price == "" ? this.listProducts[2].saleprice : this.listProducts[2].price}`,
                              "percent": `${this.listProducts[2].percent}`,
                              "link_url": `/detail?market_srl=${this.logged_info.mart_srl}&psrl=${this.listProducts[2].product_srl}&apitype=v2&tdate=${this.tDate}`
                            }
                          ]
                        },
                      }
                    }

                    fd = productObject

                    if (this.listProducts[0].name == '상품명' && this.listProducts[0].img == 'https://cdn1.critalk.com/static/images/no_image.png') {
                      this.$bvModal.msgBoxOk('리스트 타입 메시지의 [1]번째 항목에 선택된 상품이 없습니다.', msgModalOptCenter)
                    } else if (this.listProducts[1].name == '상품명' && this.listProducts[1].img == 'https://cdn1.critalk.com/static/images/no_image.png') {
                      this.$bvModal.msgBoxOk('리스트 타입 메시지의 [2]번째 항목에 선택된 상품이 없습니다.', msgModalOptCenter)
                    } else if (this.listProducts[2].name == '상품명' && this.listProducts[2].img == 'https://cdn1.critalk.com/static/images/no_image.png') {
                      this.$bvModal.msgBoxOk('리스트 타입 메시지의 [3]번째 항목에 선택된 상품이 없습니다.', msgModalOptCenter)
                    } else {
                      this.uploadImage(fd, allOrNot)
                    }

                    break

                  case 'custom':
                    //console.log(this.messageType)

                    if (this.chkImoji(this.customName + this.customPrice)) {
                      if (this.selectImageUrl.includes('https://adm.critalk.com')) {
                        if (this.sendMemberList.length == this.talkList.length) {
                          allOrNot = 'all'
                          if (this.groupName === "전체") {
                            productObject = {
                              "msg": {
                                "sending_msg_type": 'custom_single',
                                "target_msg": {
                                  'img': `${this.selectImageUrl}`,
                                  'name': `${this.customName}`,
                                  'price': `${this.customPrice}`,
                                  'pre_price': '',
                                  'percent': '',
                                  'link_url': '',
                                  'desc': '',
                                }
                              },
                              "groupnum": '0',
                              "rsrv_time": this.selectSendTime()
                            }
                          } else {
                            productObject = {
                              "msg": {
                                "sending_msg_type": 'custom_single',
                                "target_msg": {
                                  'img': `${this.selectImageUrl}`,
                                  'name': `${this.customName}`,
                                  'price': `${this.customPrice}`,
                                  'pre_price': '',
                                  'percent': '',
                                  'link_url': '',
                                  'desc': '',
                                }
                              },
                              "groupnum": this.groupValue,
                              "rsrv_time": this.selectSendTime()
                            }
                          }
                        } else {
                          allOrNot = 'not'
                          productObject = {
                            "msg": {
                              "sending_msg_type": 'custom_single',
                              "target_msg": {
                                'img': `${this.selectImageUrl}`,
                                'name': `${this.customName}`,
                                'price': `${this.customPrice}`,
                                'pre_price': '',
                                'percent': '',
                                'link_url': '',
                                'desc': '',
                              }
                            },
                            "customlist": this.sendMemberList.toString(),
                            "rsrv_time": this.selectSendTime()
                          }
                        }

                        //console.log("productObject", productObject.msg.target_msg.img)
                        //console.log("this.selectImageUrl", this.selectImageUrl)

                        let pattern = /^\s+|\s+$/g;

                        productObject.msg.target_msg.name = productObject.msg.target_msg.name.trim()
                        console.log('PRODUCT', productObject.msg.target_msg.name, productObject.msg.target_msg.name.length)

                        fd = productObject
                        if (productObject.msg.target_msg.img == 'https://cdn1.critalk.com/static/images/no_image.png') {
                          this.$bvModal.msgBoxOk('메세지에 이미지가 없습니다.\n이미지를 선택하여 주세요.', msgModalOptCenter)
                        } else if (productObject.msg.target_msg.name == '' || productObject.msg.target_msg.name.replace(pattern, '') == '') {
                          this.$bvModal.msgBoxOk('메세지에 제목이 없습니다.\n제목을 입력해주세요.', msgModalOptCenter)
                        } else {
                          this.uploadImage(fd, allOrNot)
                        }
                      } else {
                        fetch(this.selectImageUrl).then(res => res.blob()).then((result) => {
                          //console.log('result', result)

                          let temp = {
                            'sending_msg_type': 'custom_single',
                            "target_msg": {
                              'img': '',
                              'name': `${this.customName}`,
                              'price': `${this.customPrice}`,
                              'pre_price': '',
                              'percent': '',
                              'link_url': '',
                              'desc': '',
                            }
                          }

                          fd.append('msg', JSON.stringify(temp))
                          if (this.sendMemberList.length == this.talkList.length) {
                            allOrNot = 'all'
                            if (this.groupName === "전체") {
                              fd.append('groupnum', '0')
                            } else {
                              fd.append('groupnum', this.groupValue)
                            }
                          } else {
                            allOrNot = 'not'
                            fd.append('customlist', this.sendMemberList.toString())
                          }
                          fd.append('img', result, 'test.png')
                          fd.append('rsrv_time', this.selectSendTime())

                          //console.log('formData', fd.img)
                          let pattern = /^\s+|\s+$/g;
                          temp.target_msg.name = temp.target_msg.name.trim()

                          if (temp.target_msg.name == '' || temp.target_msg.name.replace(pattern, '') == '') {
                            this.$bvModal.msgBoxOk('메세지에 제목이 없습니다.\n제목을 입력해주세요.', msgModalOptCenter)
                          } else {
                            this.uploadImage(fd, allOrNot)
                          }
                        })
                      }
                    } else {
                      return
                    }

                    break
                }
              }
            })
      } else {
        this.$bvModal.msgBoxOk('선택된 수신자가 없습니다.\n수신자를 선택해주세요.', msgModalOptCenter)
      }
    },

    uploadImage(val, allOrNot) {
      switch (allOrNot) {
        case 'not' :
          //console.log("case: not")
          axios.post(`/api/macltalk/send/mosquitto/allinone/v2/${this.logged_info.mart_srl}`, val).then((res) => {
            console.log(res.data)

            if (res.data.status === 'success') {
              this.$bvModal.msgBoxOk('마클톡 전송이 시작됩니다.', msgModalOptCenter)

              this.gridApi.deselectAll()

              this.textTypeContent = ''
              this.singleProduct = {
                name: '상품명',
                img: 'https://cdn1.critalk.com/static/images/no_image.png',
                saleprice: '0000',
                price: '0000',
                percent: '00'
              }
              this.listProducts = [
                {
                  name: '상품명',
                  img: 'https://cdn1.critalk.com/static/images/no_image.png',
                  saleprice: '0000',
                  price: '0000',
                  percent: '00'
                },
                {
                  name: '상품명',
                  img: 'https://cdn1.critalk.com/static/images/no_image.png',
                  saleprice: '0000',
                  price: '0000',
                  percent: '00'
                },
                {
                  name: '상품명',
                  img: 'https://cdn1.critalk.com/static/images/no_image.png',
                  saleprice: '0000',
                  price: '0000',
                  percent: '00'
                }
              ]
              this.selectImageUrl = 'https://cdn1.critalk.com/static/images/no_image.png'
              this.customPrice = ''
              this.customName = ''
            } else if (res.data.status === 'fail') {
              if (res.data.msg === 'Restrictions on use of the service') {
                this.$bvModal.msgBoxOk('서비스 이용이 제한되었습니다. 영업 담당자에게 연락해주세요.', msgModalOptCenter)
                // 사무실로 문의바랍니다.
              }
            } else {
              this.$bvModal.msgBoxOk('마클톡 전송에 실패하였습니다.', msgModalOptCenter)
            }

          }).catch((error) => {
            console.log('error', error)
          })
          break
        case 'all':
          //console.log("case: all")
          axios.post(`/api/macltalk/send/mosquitto/allinone/v2/group/${this.logged_info.mart_srl}`, val).then((res) => {
            console.log(res.data)

            if (res.data.status === 'success') {
              this.$bvModal.msgBoxOk('마클톡 전송이 시작됩니다.', msgModalOptCenter)

              this.gridApi.deselectAll()

              this.textTypeContent = ''
              this.singleProduct = {
                name: '상품명',
                img: 'https://cdn1.critalk.com/static/images/no_image.png',
                saleprice: '0000',
                price: '0000',
                percent: '00'
              }
              this.listProducts = [
                {
                  name: '상품명',
                  img: 'https://cdn1.critalk.com/static/images/no_image.png',
                  saleprice: '0000',
                  price: '0000',
                  percent: '00'
                },
                {
                  name: '상품명',
                  img: 'https://cdn1.critalk.com/static/images/no_image.png',
                  saleprice: '0000',
                  price: '0000',
                  percent: '00'
                },
                {
                  name: '상품명',
                  img: 'https://cdn1.critalk.com/static/images/no_image.png',
                  saleprice: '0000',
                  price: '0000',
                  percent: '00'
                }
              ]
              this.selectImageUrl = 'https://cdn1.critalk.com/static/images/no_image.png'
              this.customPrice = ''
              this.customName = ''
            } else if (res.data.status === 'fail') {
              if (res.data.msg === 'Restrictions on use of the service') {
                this.$bvModal.msgBoxOk('서비스 이용이 제한되었습니다. 영업 담당자에게 연락해주세요.', msgModalOptCenter)
                // 사무실로 문의바랍니다.
              }
            } else {
              this.$bvModal.msgBoxOk('마클톡 전송에 실패하였습니다.', msgModalOptCenter)
            }

          }).catch((error) => {
            console.log('error', error)
          })
          break
      }
    },

    //그룹목록 가져오기
    getGroupList() {
      let url = `/api/web/market/group/vue/${this.logged_info.mart_srl}`

      axios.get(url).then((res) => {
        //console.log(res.data)

        this.groupList = res.data
      }).catch(err => console.log(err))
    },

    //그룹 목록 새로고침
    inquireGroup() {
      if (this.groupValue == 'all') {
        this.callTalkMember()
      } else {
        let url = `/api/web/market/group/member/vue/${this.logged_info.mart_srl}/${this.groupValue}`

        axios.get(url).then((res) => {
          //console.log(res.data)

          this.talkList = res.data.reverse()
        }).catch(err => console.log(err))
      }
      for (let i = 0; i < this.groupList.length; i++) {
        if (this.groupValue == 'all') {
          this.groupName = "전체"
        } else if (this.groupValue === this.groupList[i].mk_group_srl) {
          this.groupName = this.groupList[i].gname
          return
        }
      }
    },

    resize() {
      this.$textarea.style.height = '1px'
      this.$textarea.style.height = `${this.$textarea.scrollHeight + 12}px`
    },

    chkImoji(str) {
      const regex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2139|\ud83c\udc04|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u219a-\u21ff]|[\u2600-\u2604]|[\u2607-\u260d]|[\u2611-\u2619]|[\u2620-\u265f]|[\u2670-\u26ff])/g;

      let tmp = str.replace(regex, '')

      let find = ''

      let emojiArr = str.match(regex)
      for (var i in emojiArr) {
        console.log(emojiArr[i])
        find += emojiArr[i]
      }

      if (str !== tmp) {
        this.$bvModal.msgBoxOk(`아래 이모티콘은 사용할 수 없습니다.\n${find}\n수정 후 다시 발송해주세요.`, msgModalOptCenter)
        return false
      }

      return true
    },

    /*setMaxLength() {
      return (this.logged_info.mart_srl === 449 || this.logged_info.mart_srl === 491 || this.logged_info.mart_srl === 520 || this.logged_info.mart_srl === 522 || this.logged_info.mart_srl === 550 || this.logged_info.mart_srl === 556 || this.logged_info.mart_srl === 553) ? '100000' : '360'
    },

    maxLengthCheck() {
      return (this.logged_info.mart_srl === 449 || this.logged_info.mart_srl === 491 || this.logged_info.mart_srl === 520 || this.logged_info.mart_srl === 522 || this.logged_info.mart_srl === 550 || this.logged_info.mart_srl === 556 || this.logged_info.mart_srl === 553)
    },

    setLongTime() {
      return (this.logged_info.mart_srl === 449 || this.logged_info.mart_srl === 98 || this.logged_info.mart_srl === 248 || this.logged_info.mart_srl === 556 || this.logged_info.mart_srl === 205 || this.logged_info.mart_srl === 522 || this.logged_info.mart_srl === 550 || this.logged_info.mart_srl === 553)
    }*/

  }

}

</script>

<template>
  <Layout>
    <ResizeModal/>
    <PageHeader :title="title" :items="items"/>
    <div class="row">
      <!--   발신 설정 및 수신자 그룹 설정   -->
      <div class="col-xl-3">
        <div
            class="alert alert-warning border-0 d-flex align-items-center"
            role="alert"
            style="box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2)"
            v-if="false"
        >
          <i
              class="uil uil-exclamation-triangle font-size-16 text-warning me-2"
          ></i>
          <div class="flex-grow-1" style="word-break: keep-all">
            계약기간이 <b>21</b>일 남았습니다. (2022.05.09 ~ 2022.05.09 까지)
          </div>
        </div>
        <div
            class="alert alert-warning border-0 d-flex align-items-center"
            role="alert"
            style="box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2)"
        >
          <i
              class="uil uil-exclamation-triangle font-size-16 text-warning me-2"
          ></i>
          <div class="flex-grow-1" style="word-break: keep-all">
            고객분들의 불편을 최소화하기 위하여 발송시간을 <b>오전 8시~오후 5시로 제한</b>합니다.
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div>
              <h5 style="margin: 0;">1. 발송 설정</h5>
            </div>
            <hr class="my-3"/>

            <div style="padding-bottom: 15px;">
              <div class="row"
                   style="display: flex; align-items: center; box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2); height: 50px; border-radius: 0.25rem;">
                <div class="form-check" style="margin: 14px;">
                  <input
                      class="form-check-input"
                      type="radio"
                      name="send"
                      id="send1"
                      v-model="sendSelect"
                      value="now"
                      checked
                  />
                  <label class="form-check-label" for="send1">
                    즉시발송
                  </label>
                </div>
              </div>
            </div>

            <div>
              <div class="row"
                   style="display: flex; align-items: center; box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2); border-radius: 0.25rem;">
                <div class="form-check" style="margin: 14px;">
                  <input
                      class="form-check-input"
                      type="radio"
                      name="send"
                      id="send2"
                      value="res"
                      v-model="sendSelect"
                  />
                  <label class="form-check-label" for="send2">
                    예약발송
                  </label>
                </div>

                <div v-if="sendSelect === 'res'" style="margin-top: 0; padding: 14px; background-color: #f3f3f3">
                  <table>
                    <thead>
                    <tr>
                      <td style="width: 50%;">
                        <feather type="calendar" style="width: 14px; height: 14px; margin-right: 6px"/>
                        날짜
                      </td>
                      <td>
                        <feather type="clock" style="width: 14px; height: 14px; margin-right: 6px"/>
                        시간
                      </td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>
                        <flat-pickr
                            v-model="resvDate"
                            :config="config"
                            placeholder="발송일"
                            class="form-control"
                        ></flat-pickr>
                      </td>
                      <td>
                        <flat-pickr
                            v-model="resvTime"
                            :config="timeOptions"
                            class="form-control"
                        />
                        <!--<input type="text" class="form-control timepicker" id="resrv_time" name="resrv_time" placeholder="시간" onkeypress="checkInputTime(event);" onfocus="blur();">-->
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <div>
              <h5 style="margin: 0;">2. 수신자 그룹 설정</h5>
            </div>
            <hr class="my-3"/>

            <select class="form-select mb-2" v-model="groupValue">
              <!--
                            <option value="select">그룹선택</option>
              -->
              <option value="all">전체</option>
              <option
                  v-for="row in groupList"
                  :key="row.mk_group_srl"
                  :value="row.mk_group_srl"
              >
                {{ row.gname }}
              </option>
            </select>
            <b-button
                block
                variant="primary"
                style="width: 100%"
                @click="inquireGroup"
            >
              조회
            </b-button>
          </div>
        </div>
      </div>

      <!--   수신자 설정 Grid 사용   -->
      <div class="col-xl-4">
        <div class="card">
          <div class="card-body">
            <div class="row" style="display: flex; align-items: center; margin-bottom: -10px;">
              <div class="col-lg-7" style="display: inline-block; vertical-align: middle; float: none;">
                <h5 style="margin: 0; text-overflow: ellipsis; white-space: nowrap; overflow: hidden">3. 수신자 설정
                  ({{ groupName }} : {{ talkList.length }})</h5>
              </div>
              <div class="col-lg-5">
                <input
                    class="form-control"
                    type="text"
                    ref="default_input"
                    placeholder="수신자 검색"
                    @input="onFilterTextBoxChanged()"
                />
              </div>
            </div>
            <hr class="my-3"/>
            <div style="width: 100%">
              <ag-grid-vue
                  style="height: calc(100vh - 340px); width: 100%; text-align: center;"
                  class="ag-theme-alpine"
                  :columnDefs="columnDefs"
                  :rowData="talkList"
                  :cacheQuickFilter="true"
                  @grid-ready="onGridReady"
                  @first-data-rendered="onFirstDataRendered"
                  rowSelection="multiple"
                  suppressRowClickSelection="true"
                  suppressHorizontalScroll="true"
                  enableCellTextSelection="true"
              ></ag-grid-vue>
            </div>
          </div>
        </div>
      </div>

      <!--   메시지 작성부   -->
      <div class="col-xl-5">
        <div class="card">
          <div class="card-body">
            <div class="col-lg-6" style="display: inline-block; vertical-align: middle; float: none;">
              <h5 style="margin: 0;">4. 메시지 작성</h5>
            </div>
            <hr class="my-3"/>
            <div v-if="logged_info.business_type !== 'clan'" class="btn-group"
                 style="width:100%; height:40px; margin-bottom: 10px; box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2); border-radius: 0.25rem;">
              <b-button variant="light"
                        class="btn btn-default"
                        :class="[messageType === 'text' ? 'active' : '']"
                        style="width:25%; text-overflow: ellipsis; white-space: nowrap; overflow: hidden"
                        @click="changeTalkLayout('text')">
                <feather type="align-justify" style="height: 16px"/>
                텍스트 타입
              </b-button>
              <b-button variant="light"
                        class="btn btn-default"
                        :class="[messageType === 'single' ? 'active' : '']"
                        style="width:25%; text-overflow: ellipsis; white-space: nowrap; overflow: hidden"
                        @click="changeTalkLayout('single')"
              >
                <feather type="book" style="height: 15px"></feather>
                단일상품 타입
              </b-button>
              <b-button variant="light"
                        class="btn btn-default"
                        :class="[messageType === 'list' ? 'active' : '']"
                        style="width:25%; text-overflow: ellipsis; white-space: nowrap; overflow: hidden"
                        @click="changeTalkLayout('list')"
              >
                <i class="fa fa-list-ul"></i>
                리스트 타입
              </b-button>
              <b-button variant="light"
                        class="btn btn-default"
                        :class="[messageType === 'custom' ? 'active' : '']"
                        style="width:25%; text-overflow: ellipsis; white-space: nowrap; overflow: hidden"
                        @click="changeTalkLayout('custom')"
              >
                <i class="fa fa-align-right"></i>
                이미지편집 타입
              </b-button>
            </div>
            <div class="style-talk" style="overflow-x: hidden"
                 :style="{height: (logged_info.business_type !== 'clan' ? 'calc(100vh - 428px)' : 'calc(100vh - 380px)')}">
              <div class="talk-header row">
                <div class="col-lg-8">
                  <img :src="require('@/assets/mcimages/asset_talk_header1.png')" alt=""/>
                  <span style="vertical-align: middle">{{ this.logged_info.mart_name }}</span>
                </div>
                <div class="col-lg-4">
                  <img :src="require('@/assets/mcimages/asset_talk_header2.png')" alt="" class="pull-right"
                       style="float: right;"/>
                </div>
              </div>

              <div style="height: calc(100% - 48px); overflow-y: scroll;">
                <div v-if="messageType === 'text'"
                     style="margin-left:70px; margin-top:30px; background-color: white; margin-right: 64px; display: block; !important;"
                     class="style-alram-talk">
                  <input type="hidden" value="txt">

                  <div style="width:100%; text-align: center; position: relative;">
                    <img :src="require('@/assets/mcimages/asset_kakao_profile.svg')"
                         style="position:absolute; left:-57px; top:-30px; width:40px;">
                    <div style="position:absolute; top:-30px;">{{ this.logged_info.mart_name }}</div>

                    <div style="padding: 1rem;">
                      <div style="margin:0 auto; text-align:left">
                        <span v-if="logged_info.business_type !== 'clan'">(광고)/{{ this.logged_info.mart_name }}</span>
                        <textarea class="talk_preview_content" id="talk_preview_content"
                                  style="resize:none; border: 1px solid #ddd; width:100%; margin-top: 10px; overflow:visible; padding-bottom: 0;"
                                  placeholder="메시지를 입력해주세요."
                                  @input="checkTextcnt"
                                  v-model="textTypeContent"
                                  :maxlength="this.logged_info.macltalk_text_length"
                        ></textarea>
                        <p style="margin:0 auto; text-align:right; font-size:0.8em; color:#bbb; word-break:break-all; padding-top: 0; padding-bottom: 0;">
                          (글자수 제한 : <span id='string_size' style='font-size:1em;color: #000'>{{ textLength }}</span>/360)
                        </p>
                        <span v-if="logged_info.business_type !== 'clan'">
                        <p style="margin:0 auto; text-align:left; word-break:break-all; padding-top: 6px;">
                          ★링크★<br>https://market.critalk.com/product?market_srl={{
                            this.logged_info.mart_srl
                          }}&tdate={{ this.tDate }}
                          <br><br>
                          {{ this.logged_info.tel }}
                          <br><br>
                        </p>
                      </span>
                        <span v-else>
                        <p style="margin:0 auto; text-align:left; word-break:break-all; padding-top: 6px;">
                        ★링크★<br>{{ logged_info.macltalk_link }}<br>
                        </p>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="messageType === 'single'" style="position: relative;">

                  <div style="display: inline-block; position: absolute; left:0; top:0;">
                    <img :src="require('@/assets/mcimages/asset_kakao_profile.svg')"
                         style="margin-left: 13px; width:40px;">
                  </div>

                  <div style="display: inline-block; margin-left: 70px;">
                    <p style="margin-bottom: 9px; !important;">{{ this.logged_info.mart_name }}</p>

                    <div style="width:280px; background-color: white; margin-right: 0;"
                         class="style-alram-talk">
                      <div style="text-align: center; position: relative; padding-top:10px;">
                        <div style="position:relative; margin-top:10px; border-radius: 1px;
                            background-size:cover; width:280px; height:280px;"
                             :style="{'background-image':`url(${this.singleProduct.img})`}"
                        >
                          <b-button variant="secondary" size="sm"
                                    style="display:flex; justify-content: center; align-items: center; height: 22px; width: 22px; padding: 0; position: absolute; right: 1px; bottom: 1px;"
                                    @click="$bvModal.show('modal-center')"
                          >
                            <feather type="settings" size="16" style="vertical-align: center;"/>
                          </b-button>
                        </div>
                        <div
                            style="width:100%; padding-top: 2px; padding-bottom: 2px; padding-right: 8px; text-align: right; display: inline-block;">

                          <b-modal centered id="modal-center"
                                   title="상품선택"
                                   body-class="p-0 m-0"
                                   ok-title="취소"
                                   ok-variant="secondary"
                                   ok-only
                          >
                            <div class="modal-body" style="max-height: 600px; overflow-y: scroll; padding: 1rem 10px;">
                              <div class="row" style="padding: 0 1rem;">
                                <div class="col-md-3" style="line-height: 220%; text-align: center;">상품검색</div>

                                <div class="col-md-9">
                                  <div class="input-group">
                                    <input type="text"
                                           class="form-control"
                                           v-model="srchProductKeyword"
                                           @keypress.enter="srchProduct"
                                    >

                                    <div class="input-group-btn">
                                      <button type="button" class="btn btn-primary" size="sm"
                                              style="border-top-left-radius: 0; border-bottom-left-radius: 0;"
                                              @click="srchProduct"
                                      >
                                        검색
                                      </button>
                                    </div>

                                  </div>
                                  <span class="help-block" style="font-size: 0.8em;">해당 영역에 삽입하실 상품을 검색하여 선택해주세요!</span>
                                </div>
                              </div>

                              <div class="row">

                                <p style="text-align: left; font-weight: bold; padding-left: 16px;">검색 결과</p>

                                <div class="col-md-12"
                                     style="background: #efefef; padding-top:10px;"
                                >

                                  <p style="text-align: center; padding: 2em 0 2em 0;"
                                     v-if="srchProductList.length === 0"
                                  >
                                    검색된 결과가 없습니다.
                                  </p>

                                  <div v-for="row in srchProductList"
                                       :key="row.product_srl"
                                  >
                                    <b-card no-body style='border-radius: 0; cursor:pointer; margin-bottom: 10px'>
                                      <div @click="selectProduct(row, 'single')">
                                        <table style="border: 0; width: 100%; border-spacing: 0;">
                                          <tr>
                                            <td style="width: 90px;">
                                              <div
                                                  style="border-right:1px solid #efefef; background-size: cover;  height: 90px;"
                                                  :style="{'background-image':`url(${row.img})`}"
                                              ></div>
                                            </td>
                                            <td style="vertical-align: top; padding: 5px 10px;">
                                              <span class="info-box-text">{{ row.barcode }}</span>
                                              <span class="info-box-number">{{ row.name.replaceAll('_', ' ') }}</span>
                                              <p style='padding:0; line-height:140%; margin: 5px 0 0;font-size: 1em; word-break:break-all; text-align: left;'>
                                                <span style=' color:black;' v-if="row.saleprice !== ''">
                                                  {{ row.saleprice | comma }}원
                                                </span>
                                                <span
                                                    style='font-size: 0.9em; padding-left:5px; color:black; text-decoration:line-through'
                                                    v-if="row.saleprice !== ''">
                                                  {{ row.price | comma }}원
                                                </span>
                                                <span style='color:black;' v-else>{{ row.price | comma }}원</span>
                                              </p>
                                            </td>
                                          </tr>
                                        </table>
                                      </div>
                                    </b-card>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </b-modal>
                        </div>
                        <p style="margin:0; line-height:140%; font-size: 1em; word-break:break-all; text-align: left; padding: 0 3%;">
                          {{ singleProduct.name.replaceAll('_', ' ') }}
                        </p>

                        <p style="margin:0; line-height:140%; font-size: 1em; word-break:break-all; text-align: left; padding: 0 3%;">
                          <span id="single_product_price" style=" color:black;">
                            {{ singleProduct.saleprice == '' ? singleProduct.price : singleProduct.saleprice | comma }}원
                          </span>
                          <span id="single_product_pre_price"
                                style="font-size: 0.9em; padding-left:5px; color:black; text-decoration:line-through"
                                v-if="singleProduct.saleprice != ''"
                          >
                            {{ singleProduct.price | comma }}원
                          </span>
                          <span id="single_product_percent"
                                style="font-size: 0.9em; color:red; padding-left:5px;"
                                v-if="singleProduct.saleprice != ''"
                          >
                            {{ singleProduct.percent }}%
                          </span>
                        </p>

                        <div style="margin:8px;">
                          <!--                          <b-button block variant="light" size="sm" style="padding:.8em; margin: 0;">자세히 보기</b-button>-->
                          <b-button disabled size="sm"
                                    class="btn btn-soft-secondary w-100"
                                    style="padding: .8em; background-color: #efefef; color: black; border-color: #dddddd">
                            자세히 보기
                          </b-button>
                        </div>
                        <div
                            style="position:relative; width:100%; height: 45px; margin:0 auto; padding:0 0 0 3%;">
                          <div
                              style="position:absolute; left:0; width:100%; margin:0 auto; padding:10px 10px 10px 3%; text-align:left">
                            크리톡
                          </div>
                          <div style="position:absolute; right:0; padding:10px 3% 0 0; text-align:left">></div>
                        </div>

                      </div>
                    </div>
                  </div>

                </div>

                <div v-if="messageType === 'list'" style="position: relative">

                  <div style="display: inline-block; position: absolute; top:0; left: 0;">
                    <img :src="require('@/assets/mcimages/asset_kakao_profile.svg')"
                         style="margin-left: 13px; width:40px;">
                  </div>

                  <div style="display: inline-block; padding-left: 70px;">
                    <p style="margin-bottom: 9px; !important;">{{ this.logged_info.mart_name }}</p>

                    <div
                        style="width:280px; background-color: white; margin-right: 0;"
                        class="style-alram-talk">
                      <div>
                        <div
                            style="width:100%; margin:0 auto; padding:10px 10px 10px 3%; border-bottom: 1px solid #ddd; text-align:left">
                          {{ this.logged_info.mart_name }}
                        </div>
                        <div
                            style="position:relative; width:100%; height:100px; margin:0 auto; padding:10px 10px 10px 3%; border-bottom: 1px solid #ddd; text-align:left">
                          <table style="width: 100%">
                            <tr>
                              <td style="vertical-align: top;">
                                <p style="margin:0; padding:0; font-size: 1em; word-break:normal;">
                                  {{ listProducts[0].name.replaceAll('_', ' ') }}
                                </p>
                                <p style="margin:0; padding:0; font-size: 1em; word-break:break-all; color:#888;">
                                  {{
                                    listProducts[0].saleprice == '' ? listProducts[0].price : listProducts[0].saleprice | comma
                                  }}원
                                </p>
                              </td>
                              <td style="width: 80px;">
                                <div
                                    style="background-size:cover; right:10px; width:80px; height:80px;"
                                    :style="{'background-image':`url(${this.listProducts[0].img})`}"
                                />
                              </td>
                            </tr>
                          </table>

                          <div style="padding-top: 2px; text-align: right; display: inline-block;">
                            <b-button variant="light" size="sm" class="btn btn-soft-secondary"
                                      style="display:flex; justify-content:center; align-items:center; margin-right:0; position: absolute; right: 1px; bottom: 1px; width: 22px; height:22px; padding: 0; text-align: center;"
                                      v-b-modal.modal-center-list-0
                            >
                              <feather type="settings" size="16"></feather>
                            </b-button>
                            <b-modal centered id="modal-center-list-0"
                                     title="상품선택"
                                     body-class="p-0 m-0"
                                     ok-title="취소"
                                     ok-variant="secondary"
                                     ok-only
                            >
                              <div class="modal-body"
                                   style="max-height: 600px; overflow-y: scroll; padding: 1rem 10px;">
                                <div class="row" style="padding: 0 1rem;">
                                  <div class="col-md-3" style="line-height: 220%; text-align: center;">상품검색</div>

                                  <div class="col-md-9">
                                    <div class="input-group">
                                      <input type="text"
                                             class="form-control"
                                             v-model="srchProductKeyword"
                                             @keypress.enter="srchProduct"
                                      >

                                      <div class="input-group-btn">
                                        <button type="button" class="btn btn-primary" size="sm"
                                                style="border-top-left-radius: 0; border-bottom-left-radius: 0;"
                                                @click="srchProduct"
                                        >
                                          검색
                                        </button>
                                      </div>

                                    </div>
                                    <span class="help-block"
                                          style="font-size: 0.8em;">해당 영역에 삽입하실 상품을 검색하여 선택해주세요!</span>
                                  </div>
                                </div>

                                <div class="row">

                                  <p style="text-align: left; font-weight: bold; padding-left: 16px;">검색 결과</p>

                                  <div class="col-md-12"
                                       style="background: #efefef; padding-top:10px;"
                                  >

                                    <p style="text-align: center; padding: 2em 0 2em 0;"
                                       v-if="srchProductList.length === 0"
                                    >
                                      검색된 결과가 없습니다.
                                    </p>

                                    <div v-for="row in srchProductList"
                                         :key="row.product_srl"
                                    >
                                      <b-card no-body style='border-radius: 0; cursor:pointer; margin-bottom: 10px'>
                                        <div @click="selectProduct(row, 'list', 0)">
                                          <table style="border: 0; width: 100%; border-spacing: 0;">
                                            <tr>
                                              <td style="width: 90px;">
                                                <div
                                                    style="border-right:1px solid #efefef; background-size: cover;  height: 90px;"
                                                    :style="{'background-image':`url(${row.img})`}"
                                                ></div>
                                              </td>
                                              <td style="vertical-align: top; padding: 5px 10px;">
                                                <span class="info-box-text">{{ row.barcode }}</span>
                                                <span class="info-box-number">{{ row.name.replaceAll('_', ' ') }}</span>
                                                <p style='padding:0; line-height:140%; margin: 5px 0 0;font-size: 1em; word-break:break-all; text-align: left;'>
                                                <span style=' color:black;' v-if="row.saleprice !== ''">
                                                  {{ row.saleprice | comma }}원
                                                </span>
                                                  <span
                                                      style='font-size: 0.9em; padding-left:5px; color:black; text-decoration:line-through'
                                                      v-if="row.saleprice !== ''">
                                                  {{ row.price | comma }}원
                                                </span>
                                                  <span style='color:black;' v-else>{{ row.price | comma }}원</span>
                                                </p>
                                              </td>
                                            </tr>
                                          </table>
                                        </div>
                                      </b-card>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </b-modal>
                          </div>
                        </div>


                        <div
                            style="position:relative; width:100%; height:100px; margin:0 auto; padding:10px 10px 10px 3%; border-bottom: 1px solid #ddd; text-align:left">
                          <table style="width: 100%">
                            <tr>
                              <td style="vertical-align: top;">
                                <p style="margin:0; padding:0; font-size: 1em; word-break:normal;">
                                  {{ listProducts[1].name.replaceAll('_', ' ') }}
                                </p>
                                <p style="margin:0; padding:0; font-size: 1em; word-break:break-all; color:#888;">
                                  {{
                                    listProducts[1].saleprice == '' ? listProducts[1].price : listProducts[1].saleprice | comma
                                  }}원
                                </p>
                              </td>
                              <td style="width: 80px;">
                                <div
                                    style="background-size:cover; right:10px; width:80px; height:80px;"
                                    :style="{'background-image':`url(${this.listProducts[1].img})`}"
                                />
                              </td>
                            </tr>
                          </table>

                          <div style="padding-top: 2px; text-align: right; display: inline-block;">
                            <b-button variant="light" size="sm" class="btn btn-soft-secondary"
                                      style="display:flex; justify-content:center; align-items:center; margin-right:0; position: absolute; right: 1px; bottom: 1px; width: 22px; height:22px; padding: 0; text-align: center;"
                                      v-b-modal.modal-center-list-1
                            >
                              <feather type="settings" size="16"></feather>
                            </b-button>
                            <b-modal centered id="modal-center-list-1"
                                     title="상품선택"
                                     body-class="p-0 m-0"
                                     ok-title="취소"
                                     ok-variant="secondary"
                                     ok-only
                            >
                              <div class="modal-body"
                                   style="max-height: 600px; overflow-y: scroll; padding: 1rem 10px;">
                                <div class="row" style="padding: 0 1rem;">
                                  <div class="col-md-3" style="line-height: 220%; text-align: center;">상품검색</div>

                                  <div class="col-md-9">
                                    <div class="input-group">
                                      <input type="text"
                                             class="form-control"
                                             v-model="srchProductKeyword"
                                             @keypress.enter="srchProduct"
                                      >

                                      <div class="input-group-btn">
                                        <button type="button" class="btn btn-primary" size="sm"
                                                style="border-top-left-radius: 0; border-bottom-left-radius: 0;"
                                                @click="srchProduct"
                                        >
                                          검색
                                        </button>
                                      </div>

                                    </div>
                                    <span class="help-block"
                                          style="font-size: 0.8em;">해당 영역에 삽입하실 상품을 검색하여 선택해주세요!</span>
                                  </div>
                                </div>

                                <div class="row">

                                  <p style="text-align: left; font-weight: bold; padding-left: 16px;">검색 결과</p>

                                  <div class="col-md-12"
                                       style="background: #efefef; padding-top:10px;"
                                  >

                                    <p style="text-align: center; padding: 2em 0 2em 0;"
                                       v-if="srchProductList.length === 0"
                                    >
                                      검색된 결과가 없습니다.
                                    </p>

                                    <div v-for="row in srchProductList"
                                         :key="row.product_srl"
                                    >
                                      <b-card no-body style='border-radius: 0; cursor:pointer; margin-bottom: 10px'>
                                        <div @click="selectProduct(row, 'list', 1)">
                                          <table style="border: 0; width: 100%; border-spacing: 0;">
                                            <tr>
                                              <td style="width: 90px;">
                                                <div
                                                    style="border-right:1px solid #efefef; background-size: cover;  height: 90px;"
                                                    :style="{'background-image':`url(${row.img})`}"
                                                ></div>
                                              </td>
                                              <td style="vertical-align: top; padding: 5px 10px;">
                                                <span class="info-box-text">{{ row.barcode }}</span>
                                                <span class="info-box-number">{{ row.name.replaceAll('_', ' ') }}</span>
                                                <p style='padding:0; line-height:140%; margin: 5px 0 0;font-size: 1em; word-break:break-all; text-align: left;'>
                                                <span style=' color:black;' v-if="row.saleprice !== ''">
                                                  {{ row.saleprice | comma }}원
                                                </span>
                                                  <span
                                                      style='font-size: 0.9em; padding-left:5px; color:black; text-decoration:line-through'
                                                      v-if="row.saleprice !== ''">
                                                  {{ row.price | comma }}원
                                                </span>
                                                  <span style='color:black;' v-else>{{ row.price | comma }}원</span>
                                                </p>
                                              </td>
                                            </tr>
                                          </table>
                                        </div>
                                      </b-card>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </b-modal>
                          </div>
                        </div>


                        <div
                            style="position:relative; width:100%; height:100px; margin:0 auto; padding:10px 10px 10px 3%; border-bottom: 1px solid #ddd; text-align:left">
                          <table style="width: 100%">
                            <tr>
                              <td style="vertical-align: top;">
                                <p style="margin:0; padding:0; font-size: 1em; word-break:normal;">
                                  {{ listProducts[2].name.replaceAll('_', ' ') }}
                                </p>
                                <p style="margin:0; padding:0; font-size: 1em; word-break:break-all; color:#888;">
                                  {{
                                    listProducts[2].saleprice == '' ? listProducts[2].price : listProducts[2].saleprice | comma
                                  }}원
                                </p>
                              </td>
                              <td style="width: 80px;">
                                <div
                                    style="background-size:cover; right:10px; width:80px; height:80px;"
                                    :style="{'background-image':`url(${this.listProducts[2].img})`}"
                                />
                              </td>
                            </tr>
                          </table>

                          <div style="padding-top: 2px; text-align: right; display: inline-block;">
                            <b-button variant="light" size="sm" class="btn btn-soft-secondary"
                                      style="display:flex; justify-content:center; align-items:center; margin-right:0; position: absolute; right: 1px; bottom: 1px; width: 22px; height:22px; padding: 0; text-align: center;"
                                      v-b-modal.modal-center-list-2
                            >
                              <feather type="settings" size="16"></feather>
                            </b-button>
                            <b-modal centered id="modal-center-list-2"
                                     title="상품선택"
                                     body-class="p-0 m-0"
                                     ok-title="취소"
                                     ok-variant="secondary"
                                     ok-only
                            >
                              <div class="modal-body"
                                   style="max-height: 600px; overflow-y: scroll; padding: 1rem 10px;">
                                <div class="row" style="padding: 0 1rem;">
                                  <div class="col-md-3" style="line-height: 220%; text-align: center;">상품검색</div>

                                  <div class="col-md-9">
                                    <div class="input-group">
                                      <input type="text"
                                             class="form-control"
                                             v-model="srchProductKeyword"
                                             @keypress.enter="srchProduct"
                                      >

                                      <div class="input-group-btn">
                                        <button type="button" class="btn btn-primary" size="sm"
                                                style="border-top-left-radius: 0; border-bottom-left-radius: 0;"
                                                @click="srchProduct"
                                        >
                                          검색
                                        </button>
                                      </div>

                                    </div>
                                    <span class="help-block"
                                          style="font-size: 0.8em;">해당 영역에 삽입하실 상품을 검색하여 선택해주세요!</span>
                                  </div>
                                </div>

                                <div class="row">

                                  <p style="text-align: left; font-weight: bold; padding-left: 16px;">검색 결과</p>

                                  <div class="col-md-12"
                                       style="background: #efefef; padding-top:10px;"
                                  >

                                    <p style="text-align: center; padding: 2em 0 2em 0;"
                                       v-if="srchProductList.length === 0"
                                    >
                                      검색된 결과가 없습니다.
                                    </p>

                                    <div v-for="row in srchProductList"
                                         :key="row.product_srl"
                                    >
                                      <b-card no-body style='border-radius: 0; cursor:pointer; margin-bottom: 10px'>
                                        <div @click="selectProduct(row, 'list', 2)">
                                          <table style="border: 0; width: 100%; border-spacing: 0;">
                                            <tr>
                                              <td style="width: 90px;">
                                                <div
                                                    style="border-right:1px solid #efefef; background-size: cover;  height: 90px;"
                                                    :style="{'background-image':`url(${row.img})`}"
                                                ></div>
                                              </td>
                                              <td style="vertical-align: top; padding: 5px 10px;">
                                                <span class="info-box-text">{{ row.barcode }}</span>
                                                <span class="info-box-number">{{ row.name.replaceAll('_', ' ') }}</span>
                                                <p style='padding:0; line-height:140%; margin: 5px 0 0;font-size: 1em; word-break:break-all; text-align: left;'>
                                                <span style=' color:black;' v-if="row.saleprice !== ''">
                                                  {{ row.saleprice | comma }}원
                                                </span>
                                                  <span
                                                      style='font-size: 0.9em; padding-left:5px; color:black; text-decoration:line-through'
                                                      v-if="row.saleprice !== ''">
                                                  {{ row.price | comma }}원
                                                </span>
                                                  <span style='color:black;' v-else>{{ row.price | comma }}원</span>
                                                </p>
                                              </td>
                                            </tr>
                                          </table>
                                        </div>
                                      </b-card>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </b-modal>
                          </div>
                        </div>

                        <div style="position:relative; width:100%; height: 45px; margin:0 auto; padding:0 0 0 3%;">
                          <div
                              style="position:absolute; left:0; width:100%; margin:0 auto; padding:10px 10px 10px 3%; text-align:left">
                            크리톡
                          </div>
                          <div style="position:absolute; right:0; padding:10px 3% 0 0; text-align:left">></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="messageType === 'custom'" style="position: relative;">
                  <img :src="require('@/assets/mcimages/asset_kakao_profile.svg')"
                       style="margin-left: 13px; width:40px; position: absolute; left: 0; top: 0;">
                  <div style="display: inline-block; margin-left: 70px;">
                    <p style="margin-bottom: 9px; !important;">{{ this.logged_info.mart_name }}</p>
                    <div style="width:280px; background-color: white; margin-right: 0;"
                         class="style-alram-talk">
                      <div style="text-align: center; position: relative; padding-top:10px; padding-bottom: 10px;">
                        <div style="position:relative; border-radius: 1px;
                                    width:280px; height:280px;"
                        >
                          <div style="background-size:cover; background-position: center; background-repeat: no-repeat;
                                      width: 100%; height: 100%;"
                               :style="{'background-image':`url(${this.selectImageUrl})`}"
                          >
                          </div>
                          <b-button variant="secondary" size="sm"
                                    style="display:flex; justify-content:center; align-items:center; margin-right:0; width: 22px; height:22px; padding: 0; position: absolute; right: 1px; bottom: 1px;"
                                    v-b-modal="'image-edit'"
                          >
                            <feather type="settings" size="16" style=""></feather>
                          </b-button>
                        </div>
                        <b-modal centered id="image-edit"
                                 title="이미지편집"
                                 body-class="p-0 m-0"
                                 ok-title="편집된 이미지 적용"
                                 cancel-title="취소"
                                 size="xl"
                                 no-enforce-focus
                                 @ok="getImage"
                        >
                          <div class="modal-body" style="height: 680px;">
                            <div style="display: flex">
                              <div style="width: 13%; text-align: center"><h5>
                                샘플&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h5>
                              </div>
                              <div style="width: 8%; text-align: center"><h5>배경&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h5>
                              </div>
                            </div>
                            <div class="row" style="height: 96%;">

                              <div style="overflow-y: scroll; height: 100%; padding: 6px; width: 13%;">
                                <div v-for="row in defaultImagesUrl"
                                     :key="row.macl_bg_img"
                                >
                                  <div
                                      style="border-right:1px solid #efefef; background-size: cover; width: 100%; padding-bottom: 100%; margin-bottom: 6px;"
                                      :style="{'background-image':`url(${row.img_path})`}"
                                      @click="selectBackgroundImage(row.img_path)"
                                  />
                                </div>
                              </div>
                              <div style="overflow-y: scroll; height: 100%; padding: 6px; width: 8%;">
                                <div v-for="row in backgroundImagesUrl"
                                     :key="row.img"
                                     @click="selectBackgroundImage(row.img_path)"
                                >
                                  <div
                                      style="border-right:1px solid #efefef; background-size: cover; width: 100%; padding-bottom: 100%; margin-bottom: 6px;"
                                      :style="{'background-image':`url(${row.img_path})`}"
                                  />
                                </div>
                              </div>
                              <div style="height: 100%; width: 79%;">
                                <tui-image-editor
                                    class="col-10"
                                    ref="imageEditor"
                                    id="imgEdit"
                                    crossorigin="anonymous"
                                    :include-ui="true"
                                    :options="options"
                                />
                              </div>
                            </div>
                          </div>
                        </b-modal>
                      </div>
                      <textarea
                          style="width:100%; margin:0; line-height:140%; font-size: .8em; word-break:break-all; text-align: left; padding: 0 3%;font-weight: bold; resize: none; border: #eee 1px solid; overflow-y: hidden;"
                          placeholder="매일 먹는 좋은 식사. 다양하고 풍부한 퓨전 한정식과 식후 즐기는 차 한잔."
                          v-model="customName"
                      ></textarea>
                      <textarea
                          style="width:100%; margin:0; line-height:140%; font-size: .8em; word-break:break-all; text-align: left; padding: 0 3%;color:#aaa; resize: none; border: #eee 1px solid; overflow-y: hidden;"
                          placeholder="부담없는 가격으로 매일 먹어도 질리지 않는 퓨전 한정식. 오늘의 정식, 불고기, 비빔밥 등 블라블라.........."
                          v-model="customPrice"
                      ></textarea>

                      <div style="margin:8px;">
                        <b-button disabled size="sm"
                                  class="btn btn-soft-secondary w-100"
                                  style="padding: .8em; background-color: #efefef; color: black; border-color: #dddddd">
                          자세히 보기
                        </b-button>
                      </div>

                      <div
                          style="position:relative; width:100%; height: 45px; margin:0 auto; padding:0 0 0 3%;">
                        <div
                            style="position:absolute; left:0; width:100%; margin:0 auto; padding:10px 10px 10px 3%; text-align:left">
                          크리톡
                        </div>
                        <div style="position:absolute; right:0; padding:10px 3% 0 0; text-align:left">></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button type="button" class="btn btn-block btn-primary btn-lg"
                  style="width: 100%; border-radius: 0 0 0.25rem 0.25rem;"
                  @click="sendMaclTalk">
            마클톡 전송하기
          </button>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style>
/* 알림톡 - 알람톡스타일 */
.style-alram-talk {
  display: inline-block;
  border-radius: 16px !important;
  margin-right: 20px;
  vertical-align: top;
  margin-bottom: 20px;
}

.style-alram-talk p.title {
  background: #ffeb34 !important;
  position: relative;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
}

.style-alram-talk p.title img {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 34px;
}

/*.style-alram-talk p {
  padding: 0.5rem;
}*/

/* 알림톡 - 카톡창스타일 */
.style-talk {
  background: #b2c7da !important;
  /*border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;*/
  height: calc(100vh - 428px);
  overflow: auto;
  border-radius: 0.25rem 0.25rem 0 0;
}

.style-talk .talk-header {
  overflow: hidden;
}

.style-talk .talk-header img {
  height: 48px !important;
}

#talk_preview_content input {
  width: 75%;
  margin-bottom: 10px;
}

#talk_preview_content input:last-child {
  margin-bottom: 0 !important;
}

.btn-default.active {
  background-color: #e0e0e0;
  border-color: #dbdbdb;
}

/*상품 검색 결과*/
.info-box-text {
  display: block;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.info-box-text {
  text-transform: uppercase;
}

.info-box-number {
  display: block;
  font-weight: bold;
  font-size: 18px;
}

.ag-header-cell-label {
  justify-content: center;
}

.ag-cell {
  border-right: 1px solid !important;
  border-top: 0 !important;
  border-color: #f4f4f4 !important;
}
</style>
