<template>
  <b-modal centered id="resizeGuide" size="xl" title="이미지 파일의 크기가 2MB를 초과하여 전송할 수 없습니다." ok-only ok-title="확인">
    <div class="modal-body">
      <div style="display: flex">
        <img :src="require('@/assets/mcimages/editImageGuide.jpeg')" style="width: 630px; height: 420px;"> <!-- 9:6 -->
        <div class="explain">
          <p>
            기존 이미지를 전송하고 싶으시면 아래 순서대로 이미지 크기를 줄여주세요.<br><br>
          </p>
          <p>
            1. 우측 메뉴에서 첫번째 항목인 resize를 선택합니다.<br>
          </p>
          <p>
            2. 기존 이미지의 비율 유지를 위해 Lock Aspect Ratio를 선택합니다.<br>(이 설정은 기존 비율을 유지하기 위한 설정으로 다른 비율을 원하시면 무시하셔도 됩니다.)<br>
          </p>
          <p>
            3. Width(너비)나 Height(높이)를 1000px 이하로 줄입니다.
          </p>
        </div>
      </div>

    </div>
  </b-modal>
</template>

<script>
import './resizeModal.scss'

export default {
  name: "ResizeModal"
}
</script>

<style scoped>

</style>
